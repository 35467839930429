import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import ShopifyBtn from "../../components/shopify-btn"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import "react-responsive-carousel/lib/styles/carousel.min.css"
// import StockNotifier from "../../components/stockNotifier"

const ProductDetailSection = styled.section`
  padding: 8rem 0;
  background: var(--grey);
  h1 {
    font-family: "glacial_bold";
    text-transform: uppercase;
    font-size: 3.6rem;
    margin-bottom: 2rem;
    strong {
      font-weight: 900;
    }
  }
  .breadcrumb {
    margin-bottom: 2.4rem;
  }
  .product-description {
    margin-top: 2.4rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .product-description li {
    list-style-type: disc;
    list-style-position: inside;
  }
  .product-description h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  .price {
    font-size: 1.8rem;
    color: rgb(209, 5, 5);
    font-weight: 700;
  }
  .std {
    color: rgb(0, 0, 0);
    font-weight: 400;
  }
  ul.thumbs {
    display: grid;
    grid-template-columns: repeat(auto-fit, 80px);
    transform: translate3d(0px, 0px, 0px) !important;
    place-content: center;
    grid-gap: 1rem;
  }
  .thumb:hover {
    cursor: pointer;
  }
`

const ProductTemplate = ({ data }) => {
  const product = data.shopifyProduct
  const sortedImages = product.images.sort((a, b) =>
    a.altText > b.altText ? 1 : b.altText > a.altText ? -1 : 0
  )
  return (
    <Layout>
      <Seo title={product.title} description={product.description} />
      <ProductDetailSection>
        <div className="container">
          <ul className="breadcrumb">
            <li>
              <Link to="/">Início</Link>
            </li>
            <li>
              <Link to="/loja/">Produtos</Link>
            </li>
            <li>{product.title}</li>
          </ul>
          <div className="grid-2x" style={{ alignItems: "start" }}>
            <div>
              {product.productType !== "Pestanas Magnéticas" ? (
                <>
                  <ShopifyBtn
                    id={product.shopifyId}
                    className="btn"
                    copy="Adicionar"
                  />
                </>
              ) : (
                <ShopifyBtn
                  id={product.shopifyId}
                  className="btn"
                  copy="Adicionar"
                />
              )}
            </div>
            <div>
              <h1>{product.title}</h1>
              <div className="std price">
                {product.variants[0].compareAtPrice ? (
                  <span className="crossed-price">
                    {product.variants[0].compareAtPrice}€
                  </span>
                ) : (
                  ""
                )}
                {product.variants[0].price}€
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
                className="product-description"
              />
            </div>
          </div>
        </div>
      </ProductDetailSection>
    </Layout>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      images {
        id
        src
        altText
      }
    }
  }
`

export default ProductTemplate
